import { Settings } from '.';

const settings: Settings = {
  country: 'RO',
  noOfDecimals: 2,
  currencyPrefix: '',
  currencySuffix: '',
  localizedFormat: '',
  includeTncHeader: true,
  calculationLineOrder: [],
  isBread: false,
  hideLink: false,
};

export default settings;
